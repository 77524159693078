import React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import { Container } from "@mui/material";
import Layout from "../components/Layout";
import SeoComponent from "../components/SeoComponent";

export default function PageTemplate({ pageContext }) {
  const { data } = pageContext;
  return (
    <Layout>
      {data && (
        <>
          <SeoComponent title={data.frontmatter.title} />
          <Container
            sx={{
              py: { xs: 2, md: 4, lg: 6 },
            }}
          >
            <h1>{data.frontmatter.title}</h1>
            <MDXProvider>
              <MDXRenderer>{data.body}</MDXRenderer>
            </MDXProvider>
          </Container>
        </>
      )}
    </Layout>
  );
}
